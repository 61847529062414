import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from '@pw-utils/get'
import chunk from 'lodash/chunk'
import slice from 'lodash/slice'
import sortBy from 'lodash/sortBy'
import { useFlexSearch } from 'react-use-flexsearch'
import { TiSocialPinterest as PinterestIcon } from 'react-icons/ti'
import { FiFacebook as FacebookIcon, FiInstagram as InstagramIcon } from 'react-icons/fi'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {
  Box,
  Flex,
  Input,
  Icon,
  Image,
  Heading,
  Text,
  List,
  ListItem,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  SimpleGrid,
  useDisclosure,
  Link,
  Button,
  PseudoBox,
} from '@pw/ui'

// import Header from './Header'
import logo from '../../images/logo.png'
import { nav } from '../../data/nav'
import SEO from '../SEO'

import TopBar from './TopBar'
import Header from './Header'

// import logoInverted from '../../images/logo_inverted.png'

const baseCategories = [
  { title: 'Wellness', to: '/wellness' },
  { title: 'Type Of Trip', to: '/type-of-trip' },
  { title: 'Resources', to: '/resources' },
  { title: 'Quick Reads', to: '/quick-reads' },
]

const categories = [
  { title: 'Wellness Travel', to: '/wellness-travel' },
  { title: 'Daily Wellness', to: '/daily-wellness' },
  { title: 'Resorts', to: '/resorts' },
  { title: 'Retreats', to: '/retreats' },
  { title: 'Camping', to: '/camping' },
  { title: 'Tips', to: '/tips' },
  { title: 'Guides', to: '/guides' },
  { title: 'Gear', to: '/gear' },
  { title: 'Packing', to: '/packing' },
]

const siteMapLinks = chunk(
  [
    { title: 'Home', to: '/' },
    { title: 'About', to: '/about' },
    { title: 'Contact', to: '/contact' },
    { title: 'Blog', to: '/blog' },
    // { title: 'Press', to: '/press' },
    // { title: 'FAQ', to: '/faq' },
    // { title: 'Sitemap', to: '/sitemap' },
    ...baseCategories,
    ...categories,
  ],
  7,
)

const defaultLeftNav = [
  {
    to: '/destinations',
    label: 'Destinations',
    sub: [
      { to: '/united-states', label: 'United States' },
      {
        to: '/indonesia',
        label: 'Indonesia',
      },
      {
        to: '/cambodia',
        label: 'Cambodia',
      },
    ],
  },
  {
    to: '/wellness',
    label: 'Wellness',
    sub: [
      { to: '/daily-wellness', label: 'Daily Wellness' },
      { to: '/wellness-travel', label: 'Wellness Travel' },
    ],
  },
  {
    to: '/type-of-trip',
    label: 'Type of Trip',
    sub: [
      { to: '/retreats', label: 'Retreats' },
      { to: '/resorts', label: 'Resorts' },
      { to: '/camping', label: 'Camping' },
    ],
  },
]

const defaultRightNav = [
  {
    to: '/resources',
    label: 'Resources',
    sub: [
      { to: '/tips', label: 'Tips' },
      { to: '/guides', label: 'Guides' },
      { to: '/gear', label: 'Gear' },
      { to: '/packing', label: 'Packing' },
    ],
  },
  { to: '/quick-reads', label: 'Quick Reads' },
  { to: '/shop', label: 'Shop' },
]

export const Layout = ({
  children,
  header = 'short',
  headerTitle = null,
  location,
  meta = {},
  seo = {},
  ...props
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const data = useStaticQuery(graphql`
    query {
      localSearchPosts {
        index
        store
      }

      allContentfulTag {
        edges {
          node {
            id
            slug
            name
            blog_post {
              id
            }
          }
        }
      }

      allContentfulBlogPost(limit: 5) {
        edges {
          node {
            id
            slug
            title
            publishDate(formatString: "MMMM Do YYYY")
            description {
              description
            }
            heroImage {
              fluid(maxWidth: 1200) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }

      allInstaNode(sort: { order: DESC, fields: timestamp }) {
        edges {
          node {
            id
            localFile {
              childImageSharp {
                fixed(width: 250, height: 250) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            thumbnails {
              src
              config_width
              config_height
            }
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  `)

  /**
   *

      allInstaNode(sort: { order: DESC, fields: timestamp }) {
        edges {
          node {
            id
            localFile {
              childImageSharp {
                fixed(width: 250, height: 250) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            thumbnails {
              src
              config_width
              config_height
            }
            dimensions {
              height
              width
            }
          }
        }
      }
   */
  const index = data?.localSearchPosts?.index || ''
  const store = data?.localSearchPosts?.store || {}

  const [query, setQuery] = React.useState('')
  const onChangeSearch = (e) => setQuery(e.target.value)
  const results = useFlexSearch(query, index, JSON.parse(store))
  const [showResults, setShowResultsValue] = React.useState(false)
  const setShowResults = (value) => setTimeout(() => setShowResultsValue(value), 100)

  const posts = data?.allContentfulBlogPost?.edges || []
  const instagramPosts = data?.allInstaNode?.edges || []

  const tags = (data?.allContentfulTag?.edges || []).filter(({ node }) =>
    Boolean(node.name && node.slug),
  )

  const topTagList = chunk(
    slice(
      sortBy(tags, ({ node }) => get(node, 'blog_post.length', false)),
      0,
      10,
    ),
    5,
  )

  const [isSticky, setIsSticky] = React.useState(false)
  const stickyRef = React.useRef(null)
  const onScroll = () => {
    const el = get(stickyRef, 'current', false)

    if (el) {
      setIsSticky(el.getBoundingClientRect().bottom <= 0)
    }
  }

  const typeOfWindow = typeof window

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)

      return () => window.removeEventListener('scroll', () => onScroll)
    }
  }, [typeOfWindow])

  return (
    <Box {...props}>
      <SEO seo={{ url: location.href, ...seo }} meta={meta} />

      <TopBar display={{ base: 'none', md: 'inherit' }} zIndex="15" />

      <Header
        type={header}
        zIndex="9"
        position="relative"
        leftNav={defaultLeftNav}
        rightNav={defaultRightNav}
      />

      <Box zIndex="5" position="relative">
        {children}
      </Box>

      <Box bg="primary.50" mt={{ base: 0, md: 40 }}>
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          <Flex display={{ base: 'none', md: 'flex' }} flex="1" bg="darkBeige" p="10">
            <Container
              py="10"
              d="flex"
              alignItems="flex-end"
              justifyContent={{ base: 'space-between', md: 'flex-end' }}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Flex width="100%" maxWidth="700px" alignItems="center">
                <Image width="100%" maxWidth="200px" src={logo} opacity="0.8" mr="10" />

                <Box>
                  <Heading as="h4" mb="2">
                    Site Map
                  </Heading>

                  <SimpleGrid columns={siteMapLinks.length} spacing="10">
                    {siteMapLinks.map((list, index) => (
                      <List key={`list-${index}`} fontSize="sm">
                        {list.map((node, index) => (
                          <ListItem key={`list-item-${index}`}>
                            <Link to={node.to} py="1" display="block">
                              {node.title}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    ))}
                  </SimpleGrid>
                </Box>
              </Flex>
            </Container>
          </Flex>

          <Flex
            flex="1"
            bg="beige"
            p="10"
            justifyContent={{ base: 'center', md: 'flex-start' }}
            alignItems="center"
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              maxWidth="700px"
            >
              <Heading as="h2" pt="4" fontWeight="100">
                Get the Latest
              </Heading>
              <Heading as="h5" pb="4" fontWeight="100">
                Sign up for my Newsletter
              </Heading>

              <NewsletterForm />
            </Flex>
          </Flex>
        </Flex>
      </Box>

      <Box
        overflow="hidden"
        width="100%"
        position="relative"
        height={{ base: '75px', md: '250px ' }}
      >
        <Flex height={{ base: '75px', md: '250px ' }} position="absolute">
          {instagramPosts.map(({ node }, i) => {
            const fixedPhoto = get(node, 'localFile.childImageSharp.fixed', false)

            if (!fixedPhoto) return null

            return (
              <PseudoBox
                role="group"
                bg="primary.100"
                width={{ base: '75px', md: '250px ' }}
                height={{ base: '75px', md: '250px ' }}
                key={`ig:${i}`}
                position="relative"
                cursor="pointer"
                as={Link}
                target="_blank"
                href={`http://instagram.com/p/${node.id}`}
              >
                <PseudoBox
                  opacity="0"
                  transition="all ease-in-out 400ms"
                  top="4%"
                  right="4%"
                  bottom="4%"
                  left="4%"
                  borderWidth="1px"
                  borderColor="transparentWhite.8"
                  _groupHover={{ opacity: 1 }}
                  position="absolute"
                  zIndex="10"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="inline-block"
                    borderRadius="25%"
                    p="1"
                    color="green.700"
                    bg="transparentWhite.8"
                  >
                    <Icon as={InstagramIcon} size="32px" color="currentColor" />
                  </Box>
                </PseudoBox>

                <Img
                  fixed={fixedPhoto}
                  width={{ base: '75px', md: '250px ' }}
                  height={{ base: '75px', md: '250px ' }}
                />
              </PseudoBox>
            )
          })}
        </Flex>
      </Box>

      <Box bg="white">
        <Container
          d="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          p="10"
          maxWidth={{ md: '1200px', lg: '1400px' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Box>
            <Heading as="h4" mb="2">
              Categories
            </Heading>

            <Flex>
              <List fontSize="sm">
                <ListItem>
                  <Link to="/wellness-travel" py="1" display="block">
                    Wellness Travel
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/daily-wellness" py="1" display="block">
                    Daily Wellness
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/type-of-trip" py="1" display="block">
                    Type of Trips
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/reatreats" py="1" display="block">
                    Retreats
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/resorts" py="1" display="block">
                    Resorts
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/camping" py="1" display="block">
                    Camping
                  </Link>
                </ListItem>
              </List>

              <List ml="10" fontSize="sm">
                <ListItem>
                  <Link to="/resources" py="1" display="block">
                    Resources
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/tips" py="1" display="block">
                    Tips
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/guides" py="1" display="block">
                    Guides
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/gear" py="1" display="block">
                    Gear
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/packing" py="1" display="block">
                    Packing
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/quick-reads" py="1" display="block">
                    Quick Reads
                  </Link>
                </ListItem>
              </List>
            </Flex>
          </Box>

          {/* <Box mt={{ base: 8, md: 0 }}>
            <Heading as="h4" mb="2">
              Top Tags
            </Heading>

            <SimpleGrid columns={topTagList.length} spacing="10">
              {topTagList.map((list, index) => (
                <List key={`list-${index}`} fontSize="sm">
                  {list.map(({ node }, index) => (
                    <ListItem key={`list-item-${index}`}>
                      <Link to={`/tag/${node.slug}`} py="1" display="block">
                        {node.name}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              ))}
            </SimpleGrid>
          </Box> */}

          <Box mt={{ base: 8, md: 0 }}>
            <Heading as="h4" mb="2">
              Recent Posts
            </Heading>

            <List fontSize="sm">
              {posts
                .filter(({ node: post }) => Boolean(post.slug && post.title))
                .map(({ node: post }) => (
                  <ListItem key={post.id}>
                    <Link to={`/${post.slug}`} display="block" py="1">
                      {post.title}
                    </Link>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Container>
      </Box>

      <Box bg="white" color="gray.500">
        <Container py="5" px={{ base: 5, md: 20 }}>
          <Text py="1" fontSize="sm">
            Copyright &copy; 2020 Passport to Wellness (Brittney Ayers). All rights
            reserved.
          </Text>

          <Text py="1" fontSize="sm">
            * You may not take any images or content from this site without written
            permission. For more information, <Link to="/contact">contact us here</Link>.
          </Text>
        </Container>
      </Box>
    </Box>
  )
}

export const NewsletterForm = (props) => {
  const emailRef = React.useRef(null)
  const [emailIsLoading, setEmailIsLoading] = React.useState(false)
  const [emailNotification, setEmailNotification] = React.useState(false)
  const [emailHasError, setEmailHasError] = React.useState(false)

  const onSubmitNewsletter = (e) => {
    e.preventDefault()
    const email = emailRef.current.value

    setEmailNotification(false)
    setEmailHasError(false)
    setEmailIsLoading(true)

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      addToMailchimp(email).then((data) => {
        setEmailIsLoading(false)

        if (data.result === 'success') {
          emailRef.current.value = ''

          setEmailHasError(false)
          setEmailNotification('You have successfully been added to the newsletter')
        } else {
          emailRef.current.value = ''

          setEmailHasError(true)
          setEmailNotification(
            data.msg ? (
              <span dangerouslySetInnerHTML={{ __html: data.msg }} />
            ) : (
              'There was an unkown error'
            ),
          )
        }
      })
    } else {
      setEmailHasError(true)
      setEmailIsLoading(false)
      setEmailNotification(`Please enter a valid email address: ${email}`)
    }
  }

  return (
    <Flex
      as="form"
      flexDirection={{ base: 'column', md: 'row' }}
      {...props}
      onSubmit={onSubmitNewsletter}
    >
      <Input ref={emailRef} type="email" placeholder="your@email.com" />

      <Button
        displayType="secondary"
        type="submit"
        isLoading={emailIsLoading}
        ml={{ base: '0', md: '2' }}
        mt={{ base: 2, md: props.flexDirection === 'column' ? '2' : '0' }}
      >
        Sign Up
      </Button>

      {emailNotification && (
        <Text color={emailHasError ? 'red' : 'gray'}>{emailNotification}</Text>
      )}
    </Flex>
  )
}

export const Container = (props) => <Box w="100%" maxW="1600px" mx="auto" {...props} />

import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import { FiSearch as SearchIcon } from 'react-icons/fi'
import { Box, Stack, Icon, Input, InputGroup, InputRightElement, Link } from '@pw/ui'

const SearchBar = ({ inputGroupProps = {}, inputProps = {}, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      localSearchPosts {
        index
        store
      }
    }
  `)

  const index = data?.localSearchPosts?.index || ''
  const store = data?.localSearchPosts?.store || {}

  const [query, setQuery] = React.useState('')
  const onChangeSearch = (e) => setQuery(e.target.value)
  const results = useFlexSearch(query, index, JSON.parse(store))
  const [showResults, setShowResultsValue] = React.useState(false)
  const setShowResults = (value) => setTimeout(() => setShowResultsValue(value), 100)

  return (
    <Box position="relative" zIndex="10" {...props}>
      <InputGroup size="sm" {...inputGroupProps}>
        <Input
          isFullWidth
          value={query}
          onChange={onChangeSearch}
          onFocus={() => setShowResults(true)}
          onBlur={() => setTimeout(() => setShowResults(false), 200)}
          rounded="md"
          bg="beige"
          color="brownLightColor"
          borderColor="brownLightColor"
          _focus={{
            borderColor: 'brownLightColorHover',
            color: 'brownLightColorHover',
          }}
          _placeholder={{ color: 'brownLightColorHover' }}
          _hover={{ color: 'brownLightColorHover' }}
          type="search"
          placeholder="Search the site"
          {...inputProps}
        />

        <InputRightElement>
          <Icon as={SearchIcon} size="20px" color="darkerBeige" mr="2" />
        </InputRightElement>
      </InputGroup>

      {Boolean(query) && showResults && (
        <Box
          position="absolute"
          top="100%"
          mt="2"
          right="0"
          bg="beige"
          borderWidth="1px"
          borderColor="darkBeige"
          borderTop="none"
          roundedBottomLeft="md"
          roundedBottomRight="md"
          p="4"
          width="900%"
          maxWidth="600px"
          height="400px"
          overflowY="scroll"
        >
          <Stack spacing="2">
            {!results.length && (
              <Box boxShadow="sm" p="4" bg="white">
                No Results Were Found. Try a different search query.
              </Box>
            )}
            {results.map((item) => (
              <Box
                key={item.id}
                boxShadow="sm"
                as={RouterLink}
                to={`/${item.slug}`}
                p="4"
                bg="white"
              >
                {item.title}
              </Box>
            ))}{' '}
          </Stack>
        </Box>
      )}
    </Box>
  )
}
export default SearchBar

import React from 'react'
import { Button as UIButton } from '@chakra-ui/core'

import Link from '../Link'

const buildButtonProps = (props) => ({
  ...props,
  ...(props.to || props.href ? { as: Link } : {}),
})

export const PrimaryButton = (props) => (
  <UIButton
    fontFamily="heading"
    variantColor="primary"
    textTransform="uppercase"
    _hover={{ textDecoration: 'none', bg: 'primary.700' }}
    {...buildButtonProps(props)}
  />
)

export const SecondaryButton = ({ _hover = {}, ...props }) => (
  <UIButton
    bg="green.200"
    color="white"
    fontFamily="heading"
    _hover={{ bg: 'green.300', textDecoration: 'none', ..._hover }}
    textTransform="uppercase"
    {...buildButtonProps(props)}
  />
)

export const SecondaryButtonLink = ({ _hover = {}, ...props }) => (
  <UIButton
    variant="link"
    color="green.200"
    fontFamily="heading"
    _hover={{ color: 'green.300', textDecoration: 'none', ..._hover }}
    textTransform="uppercase"
    {...buildButtonProps(props)}
  />
)

export const Button = (props) => (
  <UIButton
    fontFamily="heading"
    textTransform="uppercase"
    _hover={{ textDecoration: 'none' }}
    {...buildButtonProps(props)}
  />
)

export const ButtonDefault = ({ displayType = 'default', ...props }) => {
  switch (displayType) {
    case 'primary':
      return <PrimaryButton {...props} />
    case 'secondary':
      return <SecondaryButton {...props} />
    case 'secondary-link':
      return <SecondaryButtonLink {...props} />
    default:
      return <Button {...props} />
  }
}

export default ButtonDefault

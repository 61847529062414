import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FiMenu as MenuIcon } from 'react-icons/fi'
import {
  Box,
  Flex,
  Link,
  PseudoBox,
  Icon,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List,
  ListItem,
} from '@pw/ui'

// import logoHorizontal from '../../images/logo_horizontal.png'
// import headerHeroPhoto from '../../images/HomepageHeaders2.jpg'

const Header = ({ type = 'short', leftNav = [], rightNav = [], ...props }) => {
  const menuRef = React.useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const data = useStaticQuery(graphql`
    query {
      HeaderImage: file(relativePath: { eq: "HomepageHeaders2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LogoImage: file(relativePath: { eq: "logo_horizontal.png" }) {
        childImageSharp {
          fixed(width: 300, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const fluidHeader = data?.HeaderImage?.childImageSharp?.fluid
  const fixedLogo = data?.LogoImage?.childImageSharp?.fixed
  // const fixedHeader = data?.HeaderImageFixed?.childImageSharp?.fixed

  return (
    <Box as="header" {...props}>
      <Box
        // borderBottomWidth="1px"
        // borderBottomColor="darkBeige"
        position="relative"
        zIndex="10"
      >
        <Flex
          alignItems="center"
          justifyContent={{ base: 'center', md: 'space-between' }}
          py="6"
        >
          <Flex
            flex="1"
            as="nav"
            alignItems="center"
            justifyContent="flex-end"
            pr="4"
            display={{ base: 'none', md: 'flex' }}
          >
            <Nav items={leftNav} />
          </Flex>

          <PseudoBox
            as={Link}
            to="/"
            opacity="0.7"
            p={{ base: 0, md: 4 }}
            _hover={{ opacity: 1 }}
          >
            {/* <Image width="300px" src={logoHorizontal} opacity="0.8" /> */}
            <Img fixed={fixedLogo} />
          </PseudoBox>

          <Flex
            flex="1"
            as="nav"
            alignItems="center"
            pl="4"
            display={{ base: 'none', md: 'flex' }}
          >
            <Nav items={rightNav} />
          </Flex>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          display={{ base: 'flex', md: 'none' }}
          px="4"
          pb="4"
        >
          <IconButton
            onClick={onOpen}
            variantColor="gray"
            variant="outline"
            icon={MenuIcon}
          />
        </Flex>
      </Box>

      {type !== 'short' && fluidHeader && <Img fluid={fluidHeader} objectFit="cover" />}

      {/* {type !== 'short' && (
        <Flex
          alignItems="center"
          justifyContent="center"
          bg="beige"
          position="relative"
          zIndex="5"
          minHeight="100px"
        >
          {fixedHeader ? (
            <Img fixed={fixedHeader} objectFit="cover" />
          ) : (
            fluidHeader && <Img fluid={fluidHeader} objectFit="cover" />
          )}
        </Flex>
      )} */}

      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={menuRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Helpful Links</DrawerHeader>

          <DrawerBody overflowY="auto">
            <List mb="8">
              {[...leftNav, ...rightNav].map((link, i) => (
                <ListItem key={`list-item:${i}:${link.label}`}>
                  <Link
                    display="block"
                    p="2"
                    to={link.link}
                    _hover={{ bg: 'beige' }}
                    borderRadius="sm"
                  >
                    {link.label}
                  </Link>

                  {link.sub && (
                    <List mb="4" fontSize="80%">
                      {link.sub.map((link, i) => (
                        <ListItem key={`list-item:${i}:${link.label}`}>
                          <Link
                            display="block"
                            p="2"
                            to={link.link}
                            _hover={{ bg: 'beige' }}
                            borderRadius="sm"
                          >
                            {link.label}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </ListItem>
              ))}
            </List>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

const Nav = ({ items, level = 0, ...props }) => (
  <PseudoBox
    as="ul"
    listStyleType="none"
    display="flex"
    alignItems="center"
    position="relative"
    {...props}
  >
    {Boolean(level) && (
      <Icon
        name="triangle-up"
        size="24px"
        color="gray.500"
        position="absolute"
        top="-14px"
        left="50%"
        transform="translate(-50%, 0)"
      />
    )}

    {items.map(({ label, sub = false, ...link }, i) => (
      <PseudoBox
        as="li"
        role="group"
        position="relative"
        py="1px"
        key={`item:${label}:${i}`}
      >
        <Link
          py={level ? 2 : 4}
          px="4"
          textTransform="uppercase"
          whiteSpace="nowrap"
          display="block"
          fontSize={level ? '90%' : 'md'}
          fontWeight={level ? 'normal' : 'bold'}
          color={{ color: level ? 'white' : 'gray.800' }}
          _hover={{
            bg: level ? 'gray.400' : 'none',
            color: level ? 'white' : 'gray.500',
          }}
          _groupHover={{ color: level ? 'white' : 'gray.500' }}
          {...link}
        >
          {label}
        </Link>

        {sub && (
          <Nav
            display="none"
            items={sub}
            position="absolute"
            top="100%"
            left="50%"
            transform="translate(-50%, 0)"
            bg="gray.500"
            level={level + 1}
            _groupHover={{ display: 'block' }}
          />
        )}
      </PseudoBox>
    ))}
  </PseudoBox>
)

export default Header

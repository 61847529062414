import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

export const buildImageUrl = url => ('//' === url.substr(0, 2) ? `https:${url}` : url)

export const SEO = ({
  seo = {},
  meta = {},
  type = false,
  children = null,
  url = false,
}) => {
  const appendToTitle = seo?.postTitle ?? 'Passport to Wellness'
  const mainTitle =
    seo?.title ||
    meta?.title ||
    (seo?.postTitle ? 'Passport to Wellness' : 'A Wellness Travel Blog')
  const title = `${mainTitle} - ${appendToTitle}`
  const description = get(
    seo,
    'description.description',
    get(
      seo,
      'description',
      'Passport to Wellness is a Wellness Travel Blog, providing a resource for wellness travel around the world. Find a cure for the common vacation with practical wellness travel tips, guides, and inspiration.',
    ),
  )
  const keywords = get(seo, 'keywords', 'wellness, travel')

  const ogTitle = get(seo, 'pinterestTitle', title)
  const ogDescription = get(
    seo,
    'pinterestDescription.pinterestDescription',
    get(seo, 'pinterestDescription', description),
  )
  const ogUrl =
    url ||
    get(seo, 'url', get(typeof window !== 'undefined' ? window : {}, 'location.href'))
  const ogSiteName = 'PassportToWellness.co'
  const ogType = type || get(seo, 'type', false)

  const pinterestImage = get(seo, 'pinterestPinPhoto.fixed.src', false)
  const pinterestImageWidth = get(seo, 'pinterestPinPhoto.fixed.width', false)
  const pinterestImageHeight = get(seo, 'pinterestPinPhoto.fixed.height', false)

  const defaultPhoto = get(seo, 'photo', get(meta, 'photo', false))

  let tags = [
    { name: 'description', content: ogDescription },
    { name: 'keywords', content: keywords },
    {
      property: 'og:url',
      content: ogUrl,
    },
    {
      property: 'og:site_name',
      content: ogSiteName,
    },
    {
      property: 'og:title',
      content: ogTitle,
    },
    {
      property: 'og:description',
      content: ogDescription,
    },
    { name: 'p:domain_verify', content: '8479031ea702c7b409692a191cea18c4' },
  ]

  if (ogType) {
    tags = [
      ...tags,
      {
        property: 'og:type',
        content: ogType,
      },
      {
        property: `${ogType}:author`,
        content: 'Passport To Wellness',
      },
    ]
  }

  if (defaultPhoto) {
    tags = [...tags, { property: 'og:image', content: buildImageUrl(defaultPhoto) }]
  }

  if (pinterestImage) {
    tags = [
      ...tags,
      {
        property: 'og:image',
        content: buildImageUrl(pinterestImage),
      },
      {
        property: 'og:image:width',
        content: pinterestImageWidth,
      },
      {
        property: 'og:image:height',
        content: pinterestImageHeight,
      },
    ]
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>

      {tags.map((tag, i) => (
        <meta key={`${i}-${tag?.property || tag?.name}`} {...tag} />
      ))}

      {children}
    </Helmet>
  )
}

export default SEO

export const query = graphql`
  fragment seoFields on ContentfulSeo {
    title
    description {
      description
    }
    pinterestTitle
    pinterestDescription {
      pinterestDescription
    }
    pinterestPinPhoto {
      fixed(width: 1000, height: 1500, quality: 80) {
        ...GatsbyContentfulFixed
      }
    }
  }
`

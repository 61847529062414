import React from 'react'
import { TiSocialPinterest as PinterestIcon } from 'react-icons/ti'
import { FiFacebook as FacebookIcon, FiInstagram as InstagramIcon } from 'react-icons/fi'
import { Box, Link, Flex, IconButton } from '@pw/ui'

import SearchBar from './SearchBar'

import { Container } from './'

const defaultNavItems = [
  { label: 'Home', to: '/' },
  { label: 'About', to: '/about' },
  { label: 'Contact', to: '/contact' },
  { label: 'Blog', to: '/blog/page/1' },
  // { label: 'Press', to: '/press' },
  // { label: 'FAQ', to: '/faq' },
  // { label: 'Sitemap', to: '/sitemap' },
]

const TopBar = ({ navItems = defaultNavItems, ...props }) => (
  <Box bg="beige" borderBottomWidth="1px" borderBottomColor="darkBeige" {...props}>
    <Container display="flex" alignItems="center" justifyContent="space-between">
      <Flex as="nav" alignItems="center">
        {navItems.map(({ label, ...linkProps }) => (
          <Link
            key={label}
            px="1"
            ml="1"
            _first={{ ml: 0 }}
            color="brownLightColor"
            _hover={{ color: 'brownLightDarkColor' }}
            fontSize="sm"
            textTransform="uppercase"
            {...linkProps}
          >
            {label}
          </Link>
        ))}
      </Flex>

      <Flex alignItems="center" width="100%" justifyContent="flex-end">
        <Flex order="1" position="relative" top="-1px" alignItems="center">
          <Link
            href="http://pinterest.com/passporttowellness"
            target="_blank"
            color="darkerBeige"
            _hover={{ color: '#827566' }}
          >
            <IconButton
              icon={PinterestIcon}
              fontSize="24px"
              area-label="Pinterest"
              ml="1"
              size="24px"
              width="24px"
              height="24px"
              variantColor="brownLight"
            />
          </Link>

          <Link
            href="http://facebook.com/passtowellness"
            target="_blank"
            color="darkerBeige"
            _hover={{ color: '#827566' }}
          >
            <IconButton
              icon={FacebookIcon}
              fontSize="17px"
              area-label="Facebook"
              ml="1"
              size="24px"
              width="24px"
              height="24px"
              variannt="outline"
              variantColor="brownLight"
            />
          </Link>

          <Link
            href="http://instagram.com/passporttowellness"
            target="_blank"
            color="darkerBeige"
            _hover={{ color: '#827566' }}
          >
            <IconButton
              icon={InstagramIcon}
              fontSize="17px"
              area-label="Instagram"
              ml="1"
              size="24px"
              width="24px"
              height="24px"
              variantColor="brownLight"
            />
          </Link>
        </Flex>

        <Box order="2" py="1" ml="4">
          <SearchBar inputProps={{ width: '300px' }} />
        </Box>
      </Flex>
    </Container>
  </Box>
)

export default TopBar

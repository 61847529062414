import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link as UILink } from '@chakra-ui/core'

export const Link = props => {
  if (props.href) {
    return <UILink {...props} />
  }

  return <UILink as={GatsbyLink} {...props} />
}

export default Link
